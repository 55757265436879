










import {Vue, Component} from "vue-property-decorator";
import {StoredRecords} from "@/request/after-sale/StoredRecords";

@Component({
    name: 'after_sale_storedRecords'
})
export default class after_sale_storedRecords extends Vue {
    modal: any = StoredRecords;
    formData: any = {};
    formRules: any = {
        name: {required: true, trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '昵称',
            key: 'nickName',
            minWidth: 140,
            tooltip: true
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '消费方式',
            key: 'consumeTypeStr',
            minWidth: 120
        },
        {
            title: '充值前金额',
            key: 'beforeFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.beforeFee))
        },
        {
            title: '充值金额',
            key: 'rechargeFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.rechargeFee))
        },
        {
            title: '当前金额',
            key: 'curFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.curFee))
        },
        {
            title: '消费金额',
            key: 'consumeFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.consumeFee))
        },
        {
            title: '累计赠送金额',
            key: 'totalGiveFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.totalGiveFee))
        },
        {
            title: '充值前等级',
            key: 'beforeMemberName',
            minWidth: 120
        },
        {
            title: '充值后等级',
            key: 'afterMemberName',
            minWidth: 120
        },
        {
            title: '员工店铺名字',
            key: 'storeName',
            minWidth: 120
        },
        {
            title: '操作员工名字',
            key: 'employeeName',
            minWidth: 120
        },
        {
            title: '记录类型',
            key: 'typeStr',
            minWidth: 120
        },
        {
            title: '消费时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
}
